.vxe-table.c--highlight .vxe-cell > .el-input:not(.el-date-editor) .el-input__inner,
.vxe-table.c--highlight .vxe-cell > .el-autocomplete .el-input__inner,
.vxe-table.c--highlight .vxe-cell > .el-select .el-input__inner,
.vxe-table.c--highlight .vxe-cell > .el-cascader .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-input:not(.el-date-editor) .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-autocomplete .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-select .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-cascader .el-input__inner, .vxe-table.c--highlight .vxe-cell > .el-input-number .el-input-number__decrease,
.vxe-table.c--highlight .vxe-cell > .el-input-number .el-input-number__increase,
.vxe-table.c--highlight .vxe-tree-cell > .el-input-number .el-input-number__decrease,
.vxe-table.c--highlight .vxe-tree-cell > .el-input-number .el-input-number__increase, .vxe-table.c--highlight .vxe-cell > .el-input-number .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-input-number .el-input__inner, .vxe-table.c--highlight .vxe-cell > .el-date-editor .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-date-editor .el-input__inner {
  border: 0; }

.vxe-table--filter-body > .el-slider .el-slider__runway, .vxe-table .vxe-cell > .el-slider .el-slider__runway, .vxe-table .vxe-tree-cell > .el-slider .el-slider__runway {
  margin: 8px 0; }
  .vxe-table--filter-body > .el-slider .el-slider__runway .el-slider__button-wrapper, .vxe-table .vxe-cell > .el-slider .el-slider__runway .el-slider__button-wrapper, .vxe-table .vxe-tree-cell > .el-slider .el-slider__runway .el-slider__button-wrapper {
    z-index: auto; }

.vxe-form .vxe-form--item-content > .el-input, .vxe-table .vxe-cell > .el-input, .vxe-table .vxe-tree-cell > .el-input,
.vxe-form .vxe-form--item-content > .el-autocomplete,
.vxe-table .vxe-cell > .el-autocomplete,
.vxe-table .vxe-tree-cell > .el-autocomplete,
.vxe-form .vxe-form--item-content > .el-input-number,
.vxe-table .vxe-cell > .el-input-number,
.vxe-table .vxe-tree-cell > .el-input-number,
.vxe-form .vxe-form--item-content > .el-select,
.vxe-table .vxe-cell > .el-select,
.vxe-table .vxe-tree-cell > .el-select,
.vxe-form .vxe-form--item-content > .el-cascader,
.vxe-table .vxe-cell > .el-cascader,
.vxe-table .vxe-tree-cell > .el-cascader,
.vxe-form .vxe-form--item-content > .el-date-editor,
.vxe-table .vxe-cell > .el-date-editor,
.vxe-table .vxe-tree-cell > .el-date-editor,
.vxe-form .vxe-form--item-content > .el-slider,
.vxe-table .vxe-cell > .el-slider,
.vxe-table .vxe-tree-cell > .el-slider {
  width: 100%; }

.vxe-table--filter-element-wrapper {
  padding: 0.8em 1em; }

.vxe-table--filter-body > .el-input,
.vxe-table--filter-body > .el-input-number,
.vxe-table--filter-body > .el-autocomplete,
.vxe-table--filter-body > .el-select,
.vxe-table--filter-body > .el-rate,
.vxe-table--filter-body > .el-slider {
  width: 180px; }

.col--valid-error > .vxe-cell > .el-input .el-input__inner,
.col--valid-error > .vxe-cell > .el-autocomplete .el-input__inner,
.col--valid-error > .vxe-cell > .el-input-number .el-input__inner,
.col--valid-error > .vxe-cell > .el-select .el-input__inner,
.col--valid-error > .vxe-cell > .el-cascader .el-input__inner,
.col--valid-error > .vxe-cell > .el-date-picker .el-input__inner,
.col--valid-error > .vxe-tree-cell > .el-input .el-input__inner,
.col--valid-error > .vxe-tree-cell > .el-autocomplete .el-input__inner,
.col--valid-error > .vxe-tree-cell > .el-input-number .el-input__inner,
.col--valid-error > .vxe-tree-cell > .el-select .el-input__inner,
.col--valid-error > .vxe-tree-cell > .el-cascader .el-input__inner,
.col--valid-error > .vxe-tree-cell > .el-date-picker .el-input__inner {
  border-color: #f56c6c; }

.vxe-table.c--highlight .vxe-cell > .el-input:not(.el-date-editor) .el-input__inner,
.vxe-table.c--highlight .vxe-cell > .el-autocomplete .el-input__inner,
.vxe-table.c--highlight .vxe-cell > .el-select .el-input__inner,
.vxe-table.c--highlight .vxe-cell > .el-cascader .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-input:not(.el-date-editor) .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-autocomplete .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-select .el-input__inner,
.vxe-table.c--highlight .vxe-tree-cell > .el-cascader .el-input__inner {
  padding: 0; }
